// typography styles here
p {
  font-size: 18px;
  line-height: 155%;
  color: $gray;
}
.ea-title {
  font-size: 38px;
  line-height: 126%;
  letter-spacing: -0.01em;
  font-weight: 700;
  color: $primary;
}

.ea-heading-one {
  font-size: 84px;
  line-height: 104%;
  font-weight: 800;
  letter-spacing: -0.07em;
  color: $black;
}
