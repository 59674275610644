// navigataion styles here
.ea-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  height: 140px;
  align-items: center;

  &__logo {
    &__icon {
      height: 31px;
      width: auto;
      object-fit: contain;
    }
  }
}
